import PrivateRoutes from "app/auth/privateRoutes";
import { lazy } from "react"
import { authRoles } from "app/auth/authRoles";
import Loadable from "app/components/Loadable";


const List = Loadable(lazy(() => import("./MarcacaoFaltasPresenca")));
 


const MarcacaoFaltasPresencaRoutes = [

    { path: "/marcacao_presenca", element: <PrivateRoutes auth={authRoles.rh}> <List /></PrivateRoutes> },
     
];

export default MarcacaoFaltasPresencaRoutes;