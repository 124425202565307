import PrivateRoutes from "app/auth/privateRoutes";
import { lazy } from "react"
import { authRoles } from "app/auth/authRoles";
import Loadable from "app/components/Loadable";

// const Edit = Loadable(lazy(() => import("./Edit")));
const Relatorio = Loadable(lazy(() => import("./index")));


const RelatorioDeDesempenhoRoutes = [
    {
        path: "/relatorio-de-desempenho",
        element:
            <PrivateRoutes
                auth={authRoles.rh}>
                <Relatorio />
            </PrivateRoutes>
    },

 
];

export default RelatorioDeDesempenhoRoutes;