import PrivateRoutes from "app/auth/privateRoutes";
import { lazy } from "react"
import { authRoles } from "app/auth/authRoles";
import Loadable from "app/components/Loadable";


const Config = Loadable(lazy(() => import("./hr")));



const ConfigRoutes = [

    { path: "/rh/configuracao", element: <PrivateRoutes auth={authRoles.rh}> <Config /></PrivateRoutes> },

];

export default ConfigRoutes;