import PrivateRoutes from "app/auth/privateRoutes";
import { lazy } from "react"
import { authRoles } from "app/auth/authRoles";
import Loadable from "app/components/Loadable";


const List = Loadable(lazy(() => import("./ListFolha")));
const ListHistorico = Loadable(lazy(() => import("./Historico")));
const ListRelatorio = Loadable(lazy(() => import("./relatorio")));


const salarioRoutes = [

    { path: "/salarios", element: <PrivateRoutes auth={authRoles.rh}> <List /></PrivateRoutes> },
    { path: "/salarios/historicos", element: <PrivateRoutes auth={authRoles.rh}> <ListHistorico /></PrivateRoutes> },

    { path: "/salarios/relatorios", element: <PrivateRoutes auth={authRoles.rh}> <ListRelatorio /></PrivateRoutes> },

];

export default salarioRoutes;