import BaseService from "app/services/BaseServices";

// services/BaseService.js
export default class FuncionarioService extends BaseService {
    constructor() {
        super("funcionarios")
    }
    buscarPorId(id) {
        console.log("ID FUIN", id);
        
        return this._handleApiRequest(() => this.api.list(`${this.apiBasePath}/${id}`), "Erro ao buscar por id");
    }
}
