import React, { Fragment, useEffect, useState } from "react";
import { Box, Tab, Tabs, Typography, styled, useTheme } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumb, SimpleCard } from "app/components";
import { generateBreadcrumbs } from "app/utils/generateBreadcrumbs";
import { Cliente } from "../Clientes/util";
import { Projecto } from "../projecto/util";
import { Usuario } from "../usuario/util";
import Processo from "../processo/util";
import Visto from "../visas/util";
import Ficheiros from "app/utils/Ficheiros"; // Importando o Ficheiros se necessário
import { CCard, CCardBody, CCardTitle, CCardHeader, CRow, CCol } from '@coreui/react';
import { CChart } from '@coreui/react-chartjs';
import { Grid } from "@mui/material"; // Importando o Grid do MUI
import DashRH from "./DashRH";

// STYLED COMPONENTS
const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: "bold",
  marginBottom: "20px"
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export default function DashAdminProjecto() {
  const { palette } = useTheme();
  const location = useLocation();
  const routeSegments = generateBreadcrumbs(location);

  // State variables
  const [value, setValue] = useState(0);
  const [totalProcesso, setTotalProcesso] = useState(0);
  const [totalCliente, setTotalCliente] = useState(0);
  const [totalProjecto, setTotalProjecto] = useState(0);
  const [totalUsuario, setTotalUsuario] = useState(0);
  const [totalVisto, setTotalVisto] = useState(0);
  const [totalDocumentos, setTotalDocumentos] = useState(0);
  const [processosDiarios, setProcessosDiarios] = useState({ labels: [], counts: [] });
  const [statusCounts, setStatusCounts] = useState([]);
  const [statusLabels, setStatusLabels] = useState([]);
  const [processosHoje, setProcessosHoje] = useState({ labels: [], counts: [] });

  // Simulating data for the cards and charts
  const cardList = [
    { name: "Total de Clientes", amount: totalCliente, path: "/clientes/list", bgColor: "success text-white" },
    { name: "Total de Projectos", amount: totalProjecto, path: "/projectos/list", bgColor: "primary text-white" },
    { name: "Total de Processos", amount: totalProcesso, path: "/processos/list", bgColor: "warning text-white" },
    { name: "Total de Documentos", amount: totalDocumentos, path: "/documentos/list", bgColor: "info text-white" },
    { name: "Total de Usuários", amount: totalUsuario, path: "/usuarios/list", bgColor: "danger text-white" },
    { name: "Total de Vistos", amount: totalVisto, path: "/vistos/query?activo=null", bgColor: "dark text-white" },
  ];

  // Function to fetch data
  async function fetchData() {
    const cliente = new Cliente();
    const projecto = new Projecto();
    const usuario = new Usuario();
    const processo = new Processo();
    const visto = new Visto();
    const ficheiros = new Ficheiros();

    setTotalCliente(await cliente.contar());
    setTotalProjecto(await projecto.contar());
    setTotalUsuario(await usuario.contar());
    setTotalVisto(await visto.contar());
    setTotalProcesso(await processo.contar());
    setTotalDocumentos(await ficheiros.contar());

    // Simulando dados para gráficos
    setProcessosDiarios({
      labels: ["Hoje", "Ontem", "Esta Semana", "Última Semana"],
      counts: [10, 5, 30, 20],
    });

    setStatusCounts([5, 10, 15, 8]); // Exemplo de contagem de status
    setStatusLabels(["Pendente", "Em Processo", "Concluído", "Cancelado"]); // Exemplo de labels de status

    setProcessosHoje({
      labels: ["Cliente A", "Cliente B", "Cliente C"],
      counts: [3, 1, 2],
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Fragment>
      <ContentBox className="analytics h-auto">
        <Box className="breadcrumb">
          <Breadcrumb routeSegments={routeSegments} />
        </Box>

        <SimpleCard>
          <Title>Relatório Geral</Title>

          <Tabs value={value} onChange={handleChange} aria-label="departments tabs">
            <Tab label="Admin" />
            <Tab label="Recursos Humanos" />
            <Tab label="Secretaria" />
            <Tab label="Processos de Visto" />
          </Tabs>

          {/* Tab Panels */}
          <TabPanel value={value} index={0}>
            
            <CCard>
              <CCardHeader>
                <CCardTitle />
              </CCardHeader>
              <CCardBody>
                <CRow>
                  {cardList.map((card, index) => (
                    <CCol key={index} md={4} className="mb-4">
                      <Link to={card.path}>
                        <CCard>
                          <CCardHeader className={`bg-${card?.bgColor}`}>
                            <h5>{card.name}</h5>
                          </CCardHeader>
                          <CCardBody className="text-center"> 
                            <h2>{card.amount}</h2>
                          </CCardBody>
                        </CCard>
                      </Link>
                    </CCol>
                  ))}
                </CRow>
              </CCardBody>
            </CCard>
            <Box pt={4}></Box>
            <Grid container spacing={3}>
              {/* Monthly Statistics Bar Chart */}
              <Grid item lg={8} md={8} sm={12} xs={12}>
                <CCard sx={{ mb: 3 }}>
                  <CCardHeader className="bg-info text-white">
                    <Title>Estatística Diária dos Pedidos</Title>
                  </CCardHeader>
                  <CChart
                    type="bar"
                    data={{
                      labels: processosDiarios.labels,
                      datasets: [
                        {
                          label: "Pedidos Diários",
                          backgroundColor: "rgba(75, 192, 192, 0.6)",
                          borderColor: "#4BC0C0",
                          borderWidth: 2,
                          data: processosDiarios.counts
                        }
                      ]
                    }}
                    options={{
                      plugins: {
                        legend: { display: true }
                      },
                      scales: {
                        y: {
                          beginAtZero: true,
                          grid: {
                            color: palette.grey[300]
                          }
                        },
                        x: {
                          grid: {
                            color: palette.grey[300]
                          }
                        }
                      }
                    }}
                  />
                </CCard>
              </Grid>

              {/* Monthly Statistics Pie Chart */}
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <CCard>
                  <CCardHeader className="bg-warning">
                    <Title>Estatística Mensal dos Pedidos</Title>
                  </CCardHeader>
                  <CChart
                    type="doughnut"
                    data={{
                      labels: statusLabels,
                      datasets: [
                        {
                          label: "Status",
                          backgroundColor: [
                            "rgba(255, 99, 132, 0.6)",
                            "rgba(54, 162, 235, 0.6)",
                            "rgba(255, 206, 86, 0.6)",
                            "rgba(75, 192, 192, 0.6)",
                          ],
                          data: statusCounts
                        }
                      ]
                    }}
                    options={{
                      plugins: {
                        legend: { display: true }
                      }
                    }}
                  />
                </CCard>
              </Grid>

              {/* Today’s Statistics */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CCard>
                  <CCardHeader className="bg-success text-white">
                    <Title>Estatísticas de Hoje</Title>
                  </CCardHeader>
                  <CChart
                    type="bar"
                    data={{
                      labels: processosHoje.labels,
                      datasets: [
                        {
                          label: "Pedidos Hoje",
                          backgroundColor: "rgba(255, 159, 64, 0.6)",
                          borderColor: "#FF9F40",
                          borderWidth: 2,
                          data: processosHoje.counts
                        }
                      ]
                    }}
                    options={{
                      plugins: {
                        legend: { display: true }
                      },
                      scales: {
                        y: {
                          beginAtZero: true,
                          grid: {
                            color: palette.grey[300]
                          }
                        },
                        x: {
                          grid: {
                            color: palette.grey[300]
                          }
                        }
                      }
                    }}
                  />
                </CCard>
              </Grid>
            </Grid>
          </TabPanel>

          {/* Adicione as outras TabPanels aqui para Recursos Humanos, Secretaria, Processos de Visto */}
          <TabPanel value={value} index={1}>
          <DashRH></DashRH>
            <Typography>Conteúdo para Recursos Humanos</Typography>
          </TabPanel>
          <TabPanel value={value} index={2}>
            {/* Conteúdo para Secretaria */}
            <Typography>Conteúdo para Secretaria</Typography>
          </TabPanel>
          <TabPanel value={value} index={3}>
            {/* Conteúdo para Processos de Visto */}
            <Typography>Conteúdo para Processos de Visto</Typography>
          </TabPanel>
        </SimpleCard>
      </ContentBox>
    </Fragment>
  );
}
