import PrivateRoutes from "app/auth/privateRoutes";
import { lazy } from "react"
import { authRoles } from "app/auth/authRoles";
import Loadable from "app/components/Loadable";



// const Edit = Loadable(lazy(() => import("./Edit")));
const List = Loadable(lazy(() => import("./Listar")));
const Detail = Loadable(lazy(() => import("./Detalhar")));
const Relatorio = Loadable(lazy(() => import("./relatorios")));

const FuncionariosRoutes = [
    {
        path: "/Funcionarios",
        element:
            <PrivateRoutes
                auth={authRoles.rh}>
                <List />
            </PrivateRoutes>
    },

    {
        path: "/funcionarios/:funcionarioId",
        element:
            <PrivateRoutes
                auth={authRoles.rh}>
                <Detail />
            </PrivateRoutes>
    },
    {
        path: "/funcionarios/relatorios",
        element:
            <PrivateRoutes
                auth={authRoles.rh}>
                <Relatorio />
            </PrivateRoutes>
    },

    // {
    //     path: "/Funcionarios/:funcionarioId/editar",
    //     element:
    //         <PrivateRoutes
    //             auth={<Edit />}>

    //         </PrivateRoutes>
    // },

];

export default FuncionariosRoutes;