import PrivateRoutes from "app/auth/privateRoutes";
import { lazy } from "react"
import { authRoles } from "app/auth/authRoles";
import Loadable from "app/components/Loadable";

// const Edit = Loadable(lazy(() => import("./Edit")));
const List = Loadable(lazy(() => import("./ListHorarios")));


const HorariosRoutes = [
    {
        path: "/horarios",
        element:
            <PrivateRoutes
                auth={authRoles.rh}>
                <List />
            </PrivateRoutes>
    },


    // {
    //     path: "/horarioss/:funcionarioId/editar",
    //     element:
    //         <PrivateRoutes
    //             auth={<Edit />}>

    //         </PrivateRoutes>
    // },

];

export default HorariosRoutes;