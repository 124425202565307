import {
  CAlert,
  CBadge,
  CButton,
  CCallout,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormSelect,
  CFormSwitch,
  CFormTextarea,
  CInputGroup,
  CInputGroupText,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSpinner
} from "@coreui/react";
import {
  AppRegistration,
  Download,
  FileOpen,
  Folder,
  FolderCopySharp,
  Person,
  Phone,
  PlusOne,
  Print,
  Search,
  TroubleshootOutlined
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  styled,
  useTheme
} from "@mui/material";
import { H1, H2, H3, Paragraph } from "app/components/Typography";
import { useApi } from "app/hooks/useApi";
import { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "./style.css";
// import { ChartLine } from "./ChartLine";
import { NotifyError } from "app/utils/toastyNotification";
import { formatDateDifference } from "app/utils/validate";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import Add from "@mui/icons-material/Add";
import useAuth from "app/hooks/useAuth";
import { ClockIcon } from "@mui/x-date-pickers";
import { Breadcrumb, SimpleCard } from "app/components";
import { generateBreadcrumbs } from "app/utils/generateBreadcrumbs";


import FuncionarioService from "../funcionarios/services/FuncionarioServices";
import getBadgeClass from "app/utils/money";
import { getStatusBadgeClass } from "../funcionarios/util";
import { CChart } from "@coreui/react-chartjs";
import { Bar, Pie } from "react-chartjs-2";

// STYLED COMPONENTS
const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between"
}));

const Title = styled("span")(() => ({
  fontSize: "1.4rem",
  fontWeight: "500",
  textTransform: "capitalize"
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: "pre",
  "& small": {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)"
  },
  "& td": { borderBottom: "none" },
  "& td:first-of-type": { paddingLeft: "16px !important" }
}));

const Small = styled("small")(({ bgcolor }) => ({
  width: 50,
  height: 15,
  color: "#fff",
  padding: "2px 8px",
  borderRadius: "4px",
  overflow: "hidden",
  background: bgcolor,
  boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)"
}));
const AppButtonRoot = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" }
  },
  "& .button": { margin: theme.spacing(1) },
  "& .input": { display: "none" }
}));
export default function Listar() {
  const { palette } = useTheme();
  const bgError = palette.error.main;
  const bgPrimary = palette.primary.main;
  const bgSecondary = palette.secondary.main;
  const [page, setPage] = useState(0);
  const [OrigemId, setOrigemId] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const goto = useNavigate();

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };
  const api = useApi();

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [filtroStatus, setFiltroStatus] = useState(0);

  function handleFiltroStatus(e) {
    setFiltroStatus((prev) => e);
  }
  const fSize = "0.775rem";
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1)
  }));

  const addAprovarShema = z.object({
    descricao: z.string()
  });


  const {
    register,
    reset,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: zodResolver(addAprovarShema),
    shouldFocusError: true,
    progressive: true
  });

  const gerarMapa = async () => {
    setLoading(prev => true)
    await api.add("pdf").then(res => {
      setLoading(prev => false);
      console.log(res);
    }).catch((err) => {
      console.log(err);
      setLoading(prev => false);
    });
  }
  let { clienteId } = useParams();
  const { user } = useAuth();
  const { gestorId } = useParams();


  // let clienteId=1
  const [loading, setLoading] = useState(false);
  const [visibleMapa, setVisibleMapa] = useState(false);
  const [Funcionarios, setFuncionarios] = useState([]);
  const [order, setOrder] = useState("DESC");
  const [orderBy, setOrderBy] = useState("nome");
  const [date, setDate] = useState();
  const funcionarioService = new FuncionarioService()
  const [gruposFuncionarios, setGruposFuncionarios] = useState({
    activo: 0,
    inactivo: 0,
    demitido: 0,
    afastado: 0,
  });
  const styleDropdown = {};
  const location = useLocation();
  const routeSegments = generateBreadcrumbs(location);
  const [visibleModalAdd, setVisibleModalAdd] = useState()
  const [dadosFuncionarios, setDadosFuncionarios] = useState({});
  const [departamentos, setDepartamentos] = useState([]);

  async function listarFuncionarios() {
    try {
      // Buscar todos os funcionários
      const response = await funcionarioService.buscar();
      console.log("FUNCIONARIOSSSSS", response);
      setFuncionarios(response?.data?.funcionarios || []);
      // Contabilizar funcionários por status
      const statusFuncionarios = {
        activo: response?.data?.funcionarios.filter(f => f.status === "activo").length,
        inactivo: response?.data?.funcionarios.filter(f => f.status === "inactivo").length,
        demitido: response?.data?.funcionarios.filter(f => f.status === "demitido").length,
        afastado: response?.data?.funcionarios.filter(f => f.status === "afastado").length,
      };
      // Atualizar o estado de gruposFuncionarios
      setGruposFuncionarios(statusFuncionarios);
    } catch (error) {
      console.error("Erro ao listar funcionários:", error);
    }
  }

  useEffect(() => {
    setDadosFuncionarios({
      totalFuncionarios: 120,
      funcionariosAtivos: 100,
      funcionariosInativos: 20,
      setores: ["Desenvolvimento", "Vendas", "Marketing", "Suporte"],
      contagemSetores: [50, 30, 20, 20],
    });
    listarFuncionarios()
  }, [])


  // + (new Date(project?.createdAt).getDay().toString()).includes(searchTerm?.toLowerCase())
  const filteredFuncionarios = Funcionarios?.filter((funcionario) =>
    funcionario?.nome?.toLowerCase().includes(searchTerm?.toLowerCase())
  )

  // Simulating data for employees and departments
  useEffect(() => {
    // Mock data fetch
    setFuncionarios([
      { name: "Funcionário A", role: "Gerente" },
      { name: "Funcionário B", role: "Assistente" },
      { name: "Funcionário C", role: "Desenvolvedor" },
    ]);

    setDepartamentos([
      { name: "Recursos Humanos", count: 5 },
      { name: "Financeiro", count: 3 },
      { name: "TI", count: 7 },
    ]);
  }, []);


  // Simulating data for employees and departments
  useEffect(() => {
    // Mock data fetch
    setFuncionarios([
      { name: "Funcionário A", role: "Gerente" },
      { name: "Funcionário B", role: "Assistente" },
      { name: "Funcionário C", role: "Desenvolvedor" },
    ]);

    setDepartamentos([
      { name: "Recursos Humanos", count: 5 },
      { name: "Financeiro", count: 3 },
      { name: "TI", count: 7 },
    ]);
  }, []);
  const barChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
    },
  };

  const pieChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "right",
      },
    },
  };
  return (
    <Fragment>
      <Box pt={4}></Box>
      <Typography variant="h5" gutterBottom>
        Dashboard de Recursos Humanos
      </Typography>

      <Grid container spacing={3}>
        {/* Card for Total Employees */}
        <Grid item xs={12} sm={4}>
          <CCard>
            <CCardHeader className="bg-danger text-white">
              <h5>Distribuição de Funcionários por Setor</h5>
            </CCardHeader>
            <CCardBody>
              {dadosFuncionarios && (
                <Pie
                  data={{
                    labels: dadosFuncionarios.setores,
                    datasets: [
                      {
                        label: "Funcionários por Setor",
                        data: dadosFuncionarios.contagemSetores,
                        backgroundColor: [
                          "#FF6384",
                          "#36A2EB",
                          "#FFCE56",
                          "#4BC0C0",
                        ],
                      },
                    ],
                  }}
                  options={pieChartOptions}
                />
              )}
            </CCardBody>
          </CCard>
        </Grid>

        {/* Card for Department Stats */}
        <Grid item xs={12} sm={8}>
          <CCard>
            <CCardHeader className="bg-success text-white">
              <CCardTitle>Funcionários por Departamento</CCardTitle>
            </CCardHeader>
            <CChart
              type="bar"
              data={{
                labels: departamentos.map(dep => dep.name),
                datasets: [
                  {
                    label: "Total de Funcionários",
                    backgroundColor: "rgba(75, 192, 192, 0.6)",
                    data: departamentos.map(dep => dep.count),
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: { display: true },
                },
                scales: {
                  y: {
                    beginAtZero: true,
                  },
                },
              }}
            />
          </CCard>
        </Grid>

        {/* List of Employees */}
        <Grid item xs={12}>
          <CCard>
            <CCardHeader className="bg-primary text-white">
              <CCardTitle>Lista de Funcionários</CCardTitle>
            </CCardHeader>
            <CCardBody>
              <Box overflow="auto">
                <ProductTable>
                  <TableHead>
                    <TableRow>

                      <TableCell sx={{ px: 2 }}>Nome</TableCell>
                      <TableCell sx={{ px: 2 }}>Telefone</TableCell>
                      <TableCell sx={{ px: 2 }}>Cargo</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {loading ? (
                      <CSpinner></CSpinner>
                    ) : (
                      <>
                        {filteredFuncionarios
                          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          ?.map((func, index) => (
                            <TableRow key={index} hover>

                              <TableCell sx={{ px: 2, textTransform: "capitalize" }}>
                                <Box display="flex" alignItems="center" gap={4}>
                                  <Paragraph>{func?.nome}</Paragraph>
                                </Box>
                              </TableCell>

                              <TableCell sx={{ px: 2 }}>
                                <Paragraph style={{ fontSize: fSize }}>
                                  <Phone /> {func?.telefone1}
                                </Paragraph>
                              </TableCell>

                              <TableCell sx={{ px: 2 }}>
                                <Paragraph style={{ fontSize: fSize }}>
                                  <CBadge className="bg-info text-white">
                                    {func?.cargo?.nome}
                                  </CBadge>
                                </Paragraph>
                              </TableCell>

                            </TableRow>
                          ))}
                      </>
                    )}
                  </TableBody>
                </ProductTable>
                <TablePagination
                  sx={{ px: 2 }}
                  page={page}
                  component="div"
                  rowsPerPage={rowsPerPage}
                  count={Funcionarios?.length}
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[50, 100, 200]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  nextIconButtonProps={{ "aria-label": "Página seguinte" }}
                  backIconButtonProps={{ "aria-label": "Página anterior" }}
                />
              </Box>


            </CCardBody>
          </CCard>
        </Grid>
      </Grid>
    </Fragment >
  );
}
