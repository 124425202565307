import { useApi } from "app/hooks/useApi";

// services/BaseService.js
export default class BaseService {
    constructor(apiBasePath) {
        this.apiBasePath = apiBasePath;
        this.api = useApi(); // Certifique-se de que useApi está definido
    }

    async gerarPDF(path, data) {
        return this._handleApiRequest(() => this.api.documento(path, data), "Erro ao Emitir PDF");
    }

    async buscarPorTipo(tipoId) {
        return this._handleApiRequest(() => this.api.listQuery(`${this.apiBasePath}/tipoId/${tipoId}`), "Erro ao buscar por tipo");
    }

    async buscar(params = {}) {
        const queryString = new URLSearchParams(params).toString();
        const url = `${this.apiBasePath}?${queryString}`;
        return this._handleApiRequest(() => this.api.listQuery(url), "Erro ao buscar");
    }
    

    async contar(params = {}) {
        const queryString = new URLSearchParams(params).toString();
        const url = `${this.apiBasePath}/count?${queryString}`;
        return this._handleApiRequest(() => this.api.listQuery(url), "Erro ao contar");
    }

    async editar(id, data) {
        return this._handleApiRequest(() => this.api.edit(`${this.apiBasePath}/${id}`, data), "Erro ao editar");
    }

    async apagar(id) {
        return this._handleApiRequest(() => this.api.delete(this.apiBasePath, id), "Erro ao apagar");
    }

    async criar(data) {
        return this._handleApiRequest(() => this.api.add(this.apiBasePath, data), "Erro ao criar");
    }

    async _handleApiRequest(apiRequest, errorMsg) {
        try {
            const resp = await apiRequest();
            console.log("RESPOSTA", resp);
            return resp?.data || resp?.data?.message
        } catch (error) {
            console.error(error);
            NotifyError(errorMsg);
        }
    }
}
